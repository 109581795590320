import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Routes from "../Routes";
import ErrorBoundary from "../components/ErrorBoundary";
import { checkLogin } from "./../store/interactions";

export default function App() {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    checkLogin(dispatch);
    if (!isAuthenticated) {
      return;
    }
  }, [isAuthenticated, dispatch]);

  return (
    <div>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </div>
  );
}
