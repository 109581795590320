import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../store/interactions";
import MPMainLogo from "../../assets/MindPoolLogo.png";
import "./Login.css";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  async function handleSubmit(event) {
    event.preventDefault();
    //console.log("Login submit");
    login(email, password, dispatch);
  }

  return (
    <div className="login">
      <form onSubmit={handleSubmit}>
        <img src={MPMainLogo} alt="MindPool" className="login-logo"></img>
        <label htmlFor="email">Email</label>

        <input
          type="email"
          id="email"
          placeholder="email address"
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor="password">Password</label>

        <input
          type="password"
          id="password"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />

        <button className="button button--filled" type="submit">
          <span>Login</span>
        </button>
      </form>
    </div>
  );
}
