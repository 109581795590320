const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "yellowfin-upload",
  },
  apiGatewayJs: {
    REGION: "us-east-1",
    URL: "https://zu5zlizy91.execute-api.us-east-1.amazonaws.com/dev",
  },
  apiGatewayUp: {
    REGION: "us-east-1",
    URL: "https://mcqdsa2bu9.execute-api.us-east-1.amazonaws.com/dev",
  },
  apiGatewayNs: {
    REGION: "us-east-1",
    URL: "https://rejx519mdk.execute-api.us-east-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_A5Oxm1QxL",
    APP_CLIENT_ID: "25hibk4e7j8685jlmoiqvn7m9s",
    IDENTITY_POOL_ID: "us-east-1:7dabb5fb-ef8e-4d3c-96fa-b50da79900d0",
  },
  Tier: {
    plans: [
      {
        name: "digital",
        count: "45",
      },
      {
        name: "free",
        count: "0",
      },
    ],
  },
  STRIPE_KEY:
    "pk_test_51I1G1TBt16rD1QvRiVTI0odkBwu6fkwSvlcsibSzipyk1ZofHNSYVx9Ye9ubbO8QNmnryLahi3XW6tezMP1lrqxY00bdd2D1re",
  SENTRY: {
    dsn: "https://974564460d5f40e5b6c695d864b6e3c9@o1086632.ingest.sentry.io/6098966",
    tracesSampleRate: 1.0,
  },
  31337: {
    exchange: {
      address: "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
    },
    MPL: {
      address: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
    },
    mETH: {
      address: "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
    },
    mDAI: {
      address: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
    },
    explorerURL: "#",
  },
  42: {
    exchange: {
      address: "0x27837973416d76ff474a11393b7e39504A8B2065",
    },
    MPL: {
      address: "0xeD6d0B685D8368EB92aca761CBE0A990B3F70b91",
    },
    mETH: {
      address: "0xFE65DD3c4A7B429ab44494EAA155CF9a2FBE0CB5",
    },
    mDAI: {
      address: "0x40f72ad455Fb606b8f52EFffF5bB05ffA3313932",
    },
    explorerURL: "https://kovan.etherscan.io/",
  },
  5: {
    exchange: {
      address: "0x20eFe5d4e6F92767311B8053E34338a85fA53e3a",
    },
    MPL: {
      address: "0x68789C387c6fcF2eD5840568eD6840488486c859",
    },
    mETH: {
      address: "0xDF7fb58706ab9E0e084B317d8E552d38e099308f",
    },
    mDAI: {
      address: "0x48445c8e6cD29E678fb4d4BbC8FBc0e73F66A67e",
    },
    explorerURL: "https://goerli.etherscan.io//",
  },
  80001: {
    exchange: {
      address: "0x40f72ad455Fb606b8f52EFffF5bB05ffA3313932",
    },
    MPL: {
      address: "0x99FbD588E6A178cf10F903c514Dc91B851186d75",
    },
    mETH: {
      address: "0xeD6d0B685D8368EB92aca761CBE0A990B3F70b91",
    },
    mDAI: {
      address: "0xFE65DD3c4A7B429ab44494EAA155CF9a2FBE0CB5",
    },
    explorerURL: "https://mumbai.polygonscan.com/",
  },
  GATrackingId: "",
  isDemo: true,
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "yellowfin-upload-prod",
  },
  apiGatewayJs: {
    REGION: "us-east-1",
    URL: "https://2ovo95o7b0.execute-api.us-east-1.amazonaws.com/prod",
  },
  apiGatewayUp: {
    REGION: "us-east-1",
    URL: "https://vvv0ztnd8b.execute-api.us-east-1.amazonaws.com/prod",
  },
  apiGatewayNs: {
    REGION: "us-east-1",
    URL: "https://fy7i0xa6za.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_YOEqFWxj3",
    APP_CLIENT_ID: "4vpov4h1j9ttliv7p3uehkjem6",
    IDENTITY_POOL_ID: "us-east-1:2f4a8b0e-daea-4281-9b85-f3adb7e80e87",
  },
  Tier: {
    plans: [
      {
        name: "digital",
        count: "45",
      },
      {
        name: "free",
        count: "0",
      },
    ],
  },
  STRIPE_KEY:
    "pk_live_51I1G1TBt16rD1QvRAJX8Z0bd32WxhpQYU0eXPJAvHP0bHahbiPohwfZGXWBE3z8U8IZHqmTqp15vFHUQDitQpm9j00KobHKIXV",
  SENTRY: {
    dsn: "https://16ec8c24d6de4a629547385b26cab8a4@o1086632.ingest.sentry.io/6107249",
    tracesSampleRate: 1.0,
  },
  GATrackingId: "G-35CDDSMXG3",
  isDemo: false,
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};

//console.log(config); // Make sure to disable this after debugging. Sensitive information for abuse.

export default config;
