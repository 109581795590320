import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Amplify } from "aws-amplify";
import reportWebVitals from "./reportWebVitals";
import config from "./config";
import "./index.css";

// APP
import App from "./containers/App";

// GLOBAL CLIENT STATE
import { Provider } from "react-redux";
import store from "./store/store";

// AMPLIFY
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "jobsearches",
        endpoint: config.apiGatewayJs.URL,
        region: config.apiGatewayJs.REGION,
      },
      {
        name: "userprofiles",
        endpoint: config.apiGatewayUp.URL,
        region: config.apiGatewayUp.REGION,
      },
      {
        name: "notes",
        endpoint: config.apiGatewayNs.URL,
        region: config.apiGatewayNs.REGION,
      },
    ],
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
