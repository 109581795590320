import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import MPMainLogo from "../assets/MindPoolLogo.png";
import "./Home.css";

export default function Home() {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
  });

  return (
    <div className="home">
      <form className="home-logo">
        <img src={MPMainLogo} alt="MindPool" className="home-logo"></img>
        <h1>DECENTRALIZED EXCHANGE</h1>
        <Link to="/dashboard" className="button button--filled">
          <button className="button--sm button--filled">
            <span className="center-login-text">Login</span>
          </button>
        </Link>
      </form>
    </div>
  );
}
